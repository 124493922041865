<template>
  <div class="job-list-page">
    <!-- Header -->
    <header class="header">
      <div class="logo">
      <img :src="require('@/assets/icon.png')" alt="WWR Logo" />
      </div>
      <div class="header-links">
        <!-- Post a Job button in place of Sign in and burger menu -->        
        <button class="post-job-button orange-button" @click="goToPostJob">Post a Job</button>
        <button class="profile-button orange-button" @click="goToProfile">Profile</button>
        <button class="logout-button orange-button" @click="logout">Logout</button>

      </div>
    </header>

    <!-- Job Category Title -->
    <div class="job-category-header">
      <h1 class="category-title">New Remote Jobs Daily!</h1>
    <span class="latest-post">Latest post just now!</span>
    </div>

    <!-- Search and Subscription -->
    <div class="search-subscribe">
      <div class="search-bar">
<input type="text" class="search-input" placeholder="Search by job title or location" v-model="searchText" @keyup.enter="fetchJobs" />
<button class="search-button" @click="fetchJobs">Search</button>
      </div>
    </div>

    <!-- Filters Section -->
    <div class="job-search-container">
      <div class="filters">
        <!-- Job Type Filter -->
        <div class="filter-wrapper">
          <button class="sort-button orange-button" @click="toggleDropdown('jobType')">Job type</button>
          <div v-if="isDropdownOpen.jobType" class="dropdown-content">
            <h4>Job type</h4>
            <div v-for="type in jobTypeOptions" :key="type" class="dropdown-item">
              <input type="checkbox" :id="type" :value="type" v-model="selectedFilters.jobType" />
              <label :for="type">{{ type }}</label>
            </div>
            <button @click="clearFilter('jobType')">Clear</button>
          </div>
        </div>

        <!-- Workplace Filter -->
        <div class="filter-wrapper">
          <button class="sort-button orange-button" @click="toggleDropdown('workplace')">Workplace</button>
          <div v-if="isDropdownOpen.workplace" class="dropdown-content">
            <h4>Workplace</h4>
            <div v-for="place in workplaceOptions" :key="place" class="dropdown-item">
              <input type="checkbox" :id="place" :value="place" v-model="selectedFilters.workplace" />
              <label :for="place">{{ place }}</label>
            </div>
            <button @click="clearFilter('workplace')">Clear</button>
          </div>
        </div>

        <!-- Country/Timezone Filter -->
        <!-- <div class="filter-wrapper">
          <button class="sort-button orange-button" @click="toggleDropdown('country')">Country or timezone</button>
          <div v-if="isDropdownOpen.country" class="dropdown-content">
            <h4>Country or timezone</h4>
            <input type="text" class="dropdown-search" placeholder="Search" v-model="selectedFilters.countrySearch" />
            <div v-for="country in countryOptions" :key="country" class="dropdown-item">
              <input type="checkbox" :id="country" :value="country" v-model="selectedFilters.country" />
              <label :for="country">{{ country }}</label>
            </div>
            <button @click="clearFilter('country')">Clear</button>
          </div>
        </div> -->

        <!-- Seniority Filter -->
        <div class="filter-wrapper">
          <button class="sort-button orange-button" @click="toggleDropdown('seniority')">Seniority</button>
          <div v-if="isDropdownOpen.seniority" class="dropdown-content">
            <h4>Seniority</h4>
            <div v-for="level in seniorityOptions" :key="level" class="dropdown-item">
              <input type="checkbox" :id="level" :value="level" v-model="selectedFilters.seniority" />
              <label :for="level">{{ level }}</label>
            </div>
            <button @click="clearFilter('seniority')">Clear</button>
          </div>
        </div>

        <!-- Pay Filter -->
        <div class="filter-wrapper">
          <button class="sort-button orange-button" @click="toggleDropdown('pay')">Pay</button>
          <div v-if="isDropdownOpen.pay" class="dropdown-content">
            <h4>Pay</h4>
            <div class="dropdown-item">
              <label for="currency">Currency:</label>
              <select v-model="selectedFilters.currency" id="currency">
                <option v-for="currency in currencyOptions" :key="currency" :value="currency">{{ currency }}</option>
              </select>
            </div>
            <div class="dropdown-item">
              <label for="pay-period">Period:</label>
              <select v-model="selectedFilters.payPeriod" id="pay-period">
                <option v-for="period in payPeriodOptions" :key="period" :value="period">{{ period }}</option>
              </select>
            </div>
            <div class="dropdown-item">
              <label for="min-salary">Minimum:</label>
              <input type="range" v-model="selectedFilters.minSalary" min="0" max="200000" id="min-salary" />
            </div>
            <span>{{ selectedFilters.minSalary }} {{ selectedFilters.currency }}/{{ selectedFilters.payPeriod }}</span>

            <button @click="clearFilter('pay')">Clear</button>
          </div>
        </div>


        <div class="filter-wrapper">
      <button class="sort-button orange-button" @click="toggleDropdown('sort')">Most recent</button>
      <div v-if="isDropdownOpen.sort" class="dropdown-content">
        <h4>Sort by</h4>
        <div class="dropdown-item">
          <input type="radio" id="most-recent" value="Most recent" v-model="selectedSort" />
          <label for="most-recent">Most recent</label>
        </div>
        <div class="dropdown-item">
          <input type="radio" id="highest-salary" value="Highest salary" v-model="selectedSort" />
          <label for="highest-salary">Highest salary</label>
        </div>
      </div>
    </div>

        <!-- Travel Filter -->
        <div class="filter-wrapper">
          <button class="sort-button orange-button" @click="toggleDropdown('travel')">Travel</button>
          <div v-if="isDropdownOpen.travel" class="dropdown-content">
            <h4>Travel</h4>
            <div v-for="travelOption in travelOptions" :key="travelOption" class="dropdown-item">
              <input type="checkbox" :id="travelOption" :value="travelOption" v-model="selectedFilters.travel" />
              <label :for="travelOption">{{ travelOption }}</label>
            </div>
            <button @click="clearFilter('travel')">Clear</button>
          </div>
        </div>
      </div>

      <!-- Sorting Options -->
      
   </div>
<div class="subscription-section">
  <div class="subscription-form">
    <p class="subscription-text">
      Get new <strong>{{ selectedFilters.jobType.length ? selectedFilters.jobType.join(', ') : 'Remote' }}</strong> jobs sent to your inbox every day!
    </p>
    <input type="email" v-model="subscriptionEmail" placeholder="you@youremail.com" class="subscription-input" />
    <button class="subscribe-button" @click="subscribe">Subscribe</button>
  </div>

  <!-- Toast Notification -->
 <div v-if="showToast" class="toast">
  You have successfully subscribed!
</div>
</div>


    <!-- Job Listings Section -->
   <!-- Job Listings Section -->
 <div class="job-cards">
      <div v-for="job in jobs" :key="job.id" class="job-card" @click="goToJobDetail(job.id)">
        <div class="job-header">
          <p class="time-posted">{{ job.timePosted }}</p>
          <p class="location">{{ job.location }}</p>
        </div>
        <h3 class="job-title">{{ job.title }}</h3>
        <p class="company-name">{{ job.company }}</p>

        <!-- Company Logo Section -->
        <div class="job-details">
          <p class="salary">{{ job.salary }}</p>
          <p class="job-type">{{ job.type }}</p>
        </div>

        <!-- Show company logo if it exists -->
        <div v-if="job.company_logo_url" class="company-logo">
          <img :src="job.company_logo_url" alt="Company Logo" class="company-logo-img" />
        </div>
      </div>

      
    </div>
  </div>
  <footer class="footer">
  <div class="footer-top">
    <span class="footer-logo">REMOTE JOB SPHERE</span>
    <div class="footer-links">
      <a href="#">Top 100 Companies</a>
      <a href="#">Blog</a>
      <a href="#">FAQ - Employers</a>
      <a href="#">Events</a>
      <a href="#">RSS</a>
      <span class="remote-jobs-posted">🔥 33,405 Remote jobs posted</span>
    </div>
  </div>
  <div class="footer-bottom">
    <span>&copy; 2024 REMOTE JOB SPHERE</span>
    <div class="footer-bottom-links">
      <a href="#">Terms</a>
      <a href="#">Guidelines</a>
      <a href="#">Privacy</a>
      <a href="#">Why Choose RJS?</a>
      <div class="social-links">
        <a href="#"><i class="fa fa-facebook"></i></a>
        <a href="#"><i class="fa fa-linkedin"></i></a>
        <a href="#"><i class="fa fa-instagram"></i></a>
        <a href="#"><i class="fa fa-twitter"></i></a>
      </div>
    </div>
  </div>
</footer>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export default {
  data() {
    return {
      latestPostTime: this.getMostRecentPost(),
      jobs: [], // Existing job list data
      jobTypeOptions: ['Full-time', 'Part-time', 'Contract'],
      workplaceOptions: ['Remote', 'Hybrid', 'On-site'],
      countryOptions: ['Anywhere', 'Albania', 'Algeria', 'American Samoa', 'Brazil', 'Canada', 'Europe', 'UK', 'USA'],
      seniorityOptions: ['Entry-level', 'Mid-level', 'Senior', 'Manager', 'Director', 'Executive'],
      currencyOptions: ['USD', 'GBP', 'EUR'],
      payPeriodOptions: ['Per year', 'Per month', 'Per hour'],
      travelOptions: ['No travel', 'Some travel', 'Lots of travel'],
      selectedFilters: {
        jobType: [],
        workplace: [],
        country: [],
        countrySearch: '',
        seniority: [],
        currency: 'USD',
        payPeriod: 'Per year',
        minSalary: 0,
        travel: [],
      },
      selectedSort: 'Most recent',
      isDropdownOpen: {
        jobType: false,
        workplace: false,
        country: false,
        seniority: false,
        pay: false,
        travel: false,
        sort: false,
      },
      page: 1,              // Added for pagination
      hasMoreJobs: true,    // Indicates if there are more jobs to load
      loading: false, 
       subscriptionEmail: '',
      showToast: false, 
   
    };
  },
  mounted() {
    this.fetchJobs();
    window.addEventListener('scroll', this.onScroll); // Add scroll event listener

  },
   beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll); // Remove scroll event listener
  },
  methods: {
     subscribe() {
    // Check if the email input is filled and formatted
    if (this.subscriptionEmail && this.validateEmail(this.subscriptionEmail)) {
      this.showToast = true;
      
      // Hide the toast after 3 seconds
      setTimeout(() => {
        this.showToast = false;
      }, 3000);
      
      // Optionally clear the email input after showing the toast
      this.subscriptionEmail = '';
    } else {
      alert("Please enter a valid email address.");
    }
  },
  validateEmail(email) {
    // Basic email validation pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  },
    logout() {
      this.$store.commit('setAuthenticated', false); // Update Vuex store to set isAuthenticated to false
      this.$router.push('/'); // Redirect to home or login page
    },
    goToProfile() {
    this.$router.push({ name: 'UserProfile' }); // Ensure 'UserProfile' route is defined in your router
  },
     getMostRecentPost() {
      return Math.floor(Math.random() * 2) + 3; 
    },
    goToJobDetail(jobId) {
      this.$router.push({ name: 'JobDetail', params: { id: jobId } });
    },
    fetchJobs() {
  const filters = {};

  // Only add parameters if they have values
  if (this.searchText) filters.search = this.searchText;
  if (this.selectedFilters.jobType.length) filters.jobType = this.selectedFilters.jobType;
  if (this.selectedFilters.workplace.length) filters.workplace = this.selectedFilters.workplace;
  if (this.selectedFilters.country.length) filters.country = this.selectedFilters.country;
  if (this.selectedFilters.seniority.length) filters.seniority = this.selectedFilters.seniority;
  if (this.selectedFilters.currency) filters.currency = this.selectedFilters.currency;
  if (this.selectedFilters.payPeriod) filters.payPeriod = this.selectedFilters.payPeriod;
  if (this.selectedFilters.minSalary) filters.minSalary = this.selectedFilters.minSalary;
  if (this.selectedFilters.travel.length) filters.travel = this.selectedFilters.travel;
  if (this.selectedSort) filters.sort = this.selectedSort;

  // Make API call
  axios
    .get("https://www.remotejobsphere.com/jobs", { params: filters })
    .then((response) => {
      this.jobs = response.data.map((job) => {
        job.timePosted = dayjs(job.created_at).fromNow();
        return job;
      });
    })
    .catch((error) => {
      console.error("Error fetching jobs:", error);
    });
},

    toggleDropdown(filter) {
    // Close all other dropdowns first
    for (const key in this.isDropdownOpen) {
      if (key !== filter) {
        this.isDropdownOpen[key] = false;
      }
    }
    // Toggle the clicked dropdown
    this.isDropdownOpen[filter] = !this.isDropdownOpen[filter];
  },
  clearFilter(filter) {
    if (filter === 'pay') {
      this.selectedFilters.minSalary = 0;
      this.selectedFilters.currency = 'USD';
      this.selectedFilters.payPeriod = 'Per year';
    } else {
      this.selectedFilters[filter] = [];
    }
    // Close the dropdown after clearing
    this.isDropdownOpen[filter] = false;
  },
    // Function to redirect to Post a Job page
    goToPostJob() {
      this.$router.push({ name: 'PostAJob' }); // Assuming you have a route called 'PostJob' for the job creation form
    },
  },
};
</script>

<style scoped>
.profile-button {
  background-color: #28a745;
  color: white;
  padding: 12px 20px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}
.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #28a745;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 1rem;
  opacity: 0;
  animation: fadeInOut 3s ease-in-out forwards;
}

/* Animation for toast */
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.subscription-section {
  background-color: #f9f9f9;
  padding: 20px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  margin: 20px 0;
  text-align: center;
}

.subscription-text {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
}

.subscription-form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.subscription-input {
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 250px;
  max-width: 80%;
}

.subscribe-button {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.subscribe-button:hover {
  background-color: #218838;
}

/* Logout Button */
.logout-button {
  background-color: #28a745;
  color: white;
  padding: 12px 20px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  margin-left: 10px; /* Add space between buttons */
}

/* Footer */
.footer {
  background-color: #1f1f1f;
  color: #ffffff;
  padding: 20px 0;
  margin-top: 40px;
}

.footer-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.footer-logo {
  font-weight: bold;
  font-size: 18px;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  margin-top: 10px;
}

.footer-links a,
.footer-bottom-links a {
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
}

.remote-jobs-posted {
  background-color: #ffe6e6;
  color: #28a745;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 15px;
  padding-left: 15px;
  border-top: 1px solid #333;
}

.footer-bottom-links {
  display: flex;
  gap: 15px;
  align-items: center;
}

.social-links a {
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  margin-left: 10px;
}

.social-links a:hover {
  color: #28a745;
}

/* General styling for buttons */
.orange-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.post-job-button {
  background-color: #28a745;
  color: white;
  padding: 12px 20px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
}

/* Job List Page */
.job-list-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.logo {
  position: relative; /* Makes the logo container the positioning reference */
  top: 134%;
  left: 7%;
}

.logo img {
  position: absolute; /* Positions the logo absolutely within the container */
  top: 1; /* Adjust as needed */
  left: 2; /* Adjust as needed */
  width: 210px;
  transform: translate(-50%, -50%); /* Centers the logo */
  z-index: 10; /* Ensures it sits above other elements */
}
.header-links {
  display: flex;
  align-items: center;
}

/* Job Category Title */
.job-category-header {
  text-align: center;
  margin-bottom: 30px;
}

.category-title {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.latest-post {
  color: #666;
}

/* Search and Subscription */
.search-subscribe {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  padding: 15px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.search-input {
  flex: 1;
  border: none;
  outline: none;
  padding: 15px;
  font-size: 16px;
  border-radius: 30px;
}

.search-button {
  background-color: #28a745;
  color: white;
  padding: 12px 30px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
}

/* Filters */
.job-search-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: center;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.filter-wrapper {
  position: relative;
}

.filter-button {
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.sort-wrapper {
  position: relative;
}

.dropdown-content {
  position: absolute;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 200px; /* Adjust width as necessary */
  z-index: 1;
}

.dropdown-content h4 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  padding-bottom: 8px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 12px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.dropdown-item:last-child {
  margin-bottom: 0; /* Remove margin on the last item */
}

.dropdown-item input[type="checkbox"] {
  margin-right: 8px; /* Space between checkbox and label */
}

.dropdown-item label {
  font-size: 14px;
  color: #555;
}

.dropdown-content button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
}

.dropdown-content button:hover {
  background-color: #e04646;
}

/* Adjusted Job Cards */
.job-cards {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 10px; /* Small padding to prevent overlap with scrollbar */
  height: calc(100vh - 300px); /* Adjust this based on other sections' heights */
}

.job-card {
  background-color: #fff;
  padding: 25px; /* Increase padding for better readability */
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15); /* Make shadow slightly darker */
  border-left: 8px solid #28a745;
  cursor: pointer;
  position: relative;
  margin-bottom: 20px; /* Add more space between cards */
}

.job-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px; /* Increase margin for clarity */
}

.time-posted,
.location {
  font-size: 1.2rem; /* Increase font size for time posted and location */
  color: #666; /* Keep color subtle but clear */
}

.job-title {
  font-size: 1.8rem; /* Make job title larger */
  font-weight: bold;
  color: #333; /* Darker color for emphasis */
  margin-bottom: 10px; /* Add space below the title */
}

.company-name {
  font-size: 1.5rem; /* Increase font size for company name */
  color: #555;
  margin-bottom: 10px; /* Add space below the company name */
}

.job-details {
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem; /* Increase font size for details */
  color: #444;
  margin-top: 10px;
}

.salary,
.job-type {
  font-size: 1.3rem; /* Make salary and job type text larger */
  color: #444;
}

.company-logo {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.company-logo-img {
  width: 120px; /* Increase logo size */
  height: 120px;
  border-radius: 5%; /* Keeps the logo rounded */
  margin-top: 10px;
}
/* Adjust logo size on smaller screens */
@media (max-width: 768px) {
  .logo img {
    width: 90px; /* Adjust the width of the logo on small screens */

  }

  .logo{
    top: 15%;
    left:10%;
  }

  .company-logo-img{
    width: 50px;
    height: 50px;
  }

  .subscription-form {
    flex-direction: column; /* Stack the items vertically */
    align-items: center;    /* Center the items */
  }

  .subscription-input {
    width: 100%; /* Make the input take up the full width on mobile */
    margin-bottom: 10px; /* Add spacing between input and button */
  }

  .subscribe-button {
    width: 100%; /* Make the button take up the full width on mobile */
  }
  .subscription-section {
    display: none; /* Hides the subscription section */
  }
.job-header{
  margin-bottom: -32px
}

}


</style>
