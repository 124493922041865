<template>
  <div class="profile-page">
    <h1>User Profile</h1>
    <!-- Display and edit user profile information -->
    <div class="profile-details">
      <h2>Profile Information</h2>
      <label>Name:</label>
      <input v-model="user.name" placeholder="Name" />
      
      <label>Email:</label>
      <input v-model="user.email" placeholder="Email" disabled />

      <!-- Additional fields as necessary -->
    </div>

    <!-- Display list of user's posted jobs with edit/delete options -->
    <div class="job-list">
      <h2>Your Job Postings</h2>
      <div v-for="job in userJobs" :key="job.id" class="job-item">
        <h3>{{ job.title }}</h3>
        <p>{{ job.description }}</p>
        <button @click="editJob(job.id)">Edit</button>
        <button @click="deleteJob(job.id)">Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        name: 'John Doe',
        email: 'john.doe@example.com',
        // Add other profile fields as needed
      },
      userJobs: [
        // Sample data; ideally fetched from your backend
        { id: 1, title: 'Frontend Developer', description: 'Remote - Full-time' },
        { id: 2, title: 'Backend Developer', description: 'Remote - Part-time' },
      ],
    };
  },
  methods: {
    editJob(jobId) {
      // Logic to navigate to job editing page or open edit form
      this.$router.push({ name: 'EditJob', params: { id: jobId } });
    },
    deleteJob(jobId) {
      // Logic to delete job posting
      if (confirm('Are you sure you want to delete this job posting?')) {
        this.userJobs = this.userJobs.filter(job => job.id !== jobId);
      }
    },
  },
};
</script>

<style scoped>
.profile-page {
  padding: 20px;
}

.profile-details, .job-list {
  margin-bottom: 30px;
}

label {
  font-weight: bold;
  display: block;
  margin-top: 10px;
}

input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.job-item {
  margin-bottom: 15px;
}

button {
  margin-right: 10px;
  background-color: #28a745;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #218838;
}
</style>
