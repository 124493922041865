<template>
  <div class="job-detail">
    <!-- Styled back button -->
    <button class="back-button" @click="$router.push('/jobs')">Back to all jobs</button>

    <div class="job-container">
      <!-- Left column: Job information -->
      <div class="job-info">
        <h1>{{ job.title }}</h1>
        <div class="job-tags">
          <span v-for="tag in job.tags" :key="tag" class="job-tag">{{ tag }}</span>
        </div>

        <h2 class="time-posted">Posted: {{ job.timePosted }}</h2>

        <!-- Render the description nicely -->
        <div class="job-description" v-html="formattedDescription"></div>

        <a :href="job.apply_link" class="apply-button" target="_blank">Apply for this position</a>
      </div>

      <!-- Right column: Company information -->
      <div class="company-info">
        <!-- Company logo at the top-right corner -->
        <div class="company-card">
          <h3>{{ job.company }}</h3>
          <a :href="job.company_website" class="company-website" target="_blank">Company Website</a>
          <h3 class="location">Location: {{ job.location }}</h3>
          <p>Jobs Posted: 1</p> <!-- Example job count, adjust as necessary -->
        </div>
        <!-- Apply button and view company profile -->
        <a :href="job.apply_link" class="apply-button-side" target="_blank">Apply for this position</a>
        <a class="view-all-jobs" href="#">View company profile & all jobs</a>

        <!-- Display the company logo if it exists -->
        <img v-if="job.company_logo_url" :src="job.company_logo_url" alt="Company Logo" class="company-logo" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export default {
  props: ['id'], // This will receive the job ID as a prop from the route
  data() {
    return {
      job: {}, // To hold the job details
    };
  },
  mounted() {
    this.fetchJob();
  },
  computed: {
    formattedDescription() {
      // Format the job description with proper line breaks or paragraphs
      if (this.job.description) {
        // Splitting the description into paragraphs
        const paragraphs = this.job.description.split('\n\n').map(p => `<p>${p}</p>`).join('');
        return paragraphs;
      }
      return '';
    }
  },
  methods: {
    fetchJob() {
      axios.get(`https://www.remotejobsphere.com/jobs/${this.id}`)
        .then(response => {
          this.job = response.data;

          // Calculate the time ago for the posted date (or created_at if posted_date is not available)
          const postedTime = this.job.posted_date || this.job.created_at;
          this.job.timePosted = dayjs(postedTime).fromNow();  // Calculate "time ago"
        })
        .catch(error => {
          console.error('Error fetching job details:', error);
        });
    }
  },
};
</script>
<style scoped>
.job-detail {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: left; /* Ensures the entire job detail page is left-aligned */
}

.job-container {
  display: flex;
  gap: 20px;
  align-items: flex-start; /* Ensures that the company info card aligns with the top of the job info */
}

/* Left column: Job information */
.job-info {
  flex: 2;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: left; /* Left-aligns the job information section */
}

.job-tags {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap; /* This allows the tags to wrap to the next line */
  gap: 10px; /* Adds spacing between the tags */
}

.job-tag {
  display: inline-block;
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
}

.time-posted {
  color: #666;
  text-align: left; /* Ensures the "Posted" time is left-aligned */
}

/* Improved description styling */
.job-description {
  white-space: normal; /* Ensures that text formatting like line breaks is respected */
  margin: 20px 0;
  line-height: 1.6; /* Improve readability with more line spacing */
  max-width: 800px; /* Limit the width of the description to prevent it from stretching across the page */
  text-align: left; /* Ensures the job description is left-aligned */
  font-size: 18px; /* Adjust font size for larger text */
}

.job-description p {
  margin-bottom: 15px; /* Add spacing between paragraphs */
}

.apply-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
  text-align: center;
}

/* Right column: Company information */
.company-info {
  flex: 1;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative; /* To position the logo within the company info card */
  height: fit-content;
}

.company-logo {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 110px; /* Adjust size as necessary */
  height: 100px;
  border-radius: 5%; /* Optional to make it circular */
  object-fit: cover; /* Ensures the logo is properly scaled */
}

.company-card {
  margin-bottom: 20px;
}

.location {
  font-size: 18px;
  color: #666;
}

.company-website {
  display: inline-block;
  margin: 10px 0;
  color: #0073e6;
  text-decoration: none;
  text-align: left;
}

.apply-button-side {
  display: inline-block;
  width: 100%;
  padding: 10px;
  background-color: #28a745;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  /* margin-top: 10px; */
  text-align: center;
}

.view-all-jobs {
  display: inline-block;
  margin-top: 10px;
  color: #0073e6;
  text-decoration: none;
  text-align: center;
}

/* Styled back button */
.back-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}
/* Mobile Adjustments */
@media (max-width: 768px) {
  .job-container {
    flex-direction: column; /* Stack the columns vertically on mobile */
    gap: 20px; /* Adds space between the stacked elements */
  }

  /* Change order to place company info at the top */
  .company-info {
    order: -1; /* Move the company info section to the top */
  }

  /* Adjust job info for smaller screens */
  .job-info {
    flex: 1;
    padding: 15px; /* Add padding to the job info */
  }

  .company-info {
    flex: 1;
    padding: 15px; /* Adjust padding for company info */
    box-shadow: none; /* Remove box-shadow for better fit */
    /* position: static;  */
    width: 90%;
    height: auto; /* Let the height be auto */
  }

  /* Adjust job title and description font size for readability */
  .job-title {
    font-size: 1.5rem; /* Make the job title smaller */
  }

  .job-description {
    font-size: 1rem; /* Adjust description font size */
    line-height: 1.5; /* Improve line height */
    max-width: 100%; /* Make the description fit the screen */
    padding: 0 15px; /* Add some padding */
  }

  .company-website {
    font-size: 1rem; /* Adjust website link size */
  }

  .apply-button, .apply-button-side {
    width: 100%; /* Make apply buttons full-width */
    padding: 12px 0; /* Add more padding for better click area */
  }

  .company-logo {
    width: 80px; /* Resize the company logo for small screens */
    height: 80px;
    object-fit: contain; /* Ensure logo scales properly */
  }

  .back-button {
    width: 100%; /* Make the back button full width */
    padding: 12px 0; /* Adjust padding for easy tapping */
    text-align: center;
  }

  .view-all-jobs {
    display: block;
    margin-top: 10px;
    text-align: center;
  }

  /* Adjust job tags to be more readable on small screens */
  .job-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 15px;
  }

  .job-tag {
    font-size: 0.9rem; /* Make the tags smaller for mobile */
    padding: 6px 12px;
    background-color: #f0f0f0;
    border-radius: 5px;
  }

  .location, .time-posted {
    font-size: 1rem; /* Adjust font size for location and time posted */
    color: #666;
  }
}


</style>

