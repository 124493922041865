<template>
  <div class="plan-selection-container">
    <div class="left-section">
      <div class="header-section2">
        <h2>Choose your plan</h2>
        <!-- <p class="discount-info">50% discount</p> -->
      </div>
      <div class="plan-options">
        <div
          class="plan"
          v-for="plan in plans"
          :key="plan.id"
          :class="{ popular: plan.popular }"
          @click="redirectToStripe(plan.price_id)" 
        >
        
          <div class="plan-header">
            <div v-if="plan.popular" class="most-popular-banner">
            <span>Most Popular</span>
          </div>
            <h3>{{ plan.name }}</h3>
            <div class="price-container">
              <p class="price-before">{{ plan.originalPrice }}</p>
              <p class="price-now">{{ plan.discountedPrice }}</p>
              <p class="price-day">{{ plan.pricePerDay }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-section">
        <p class="payment-info">
          Without cancellation, before the selected discounted intro plan ends, I accept that RemoteJobSphere will automatically charge $39.99 USD every 1 month until I cancel. Cancel anytime.
        </p>
        <div class="payment-icons">
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/Visa_2021.svg/1920px-Visa_2021.svg.png" alt="Visa" />
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Mastercard_2019_logo.svg/1200px-Mastercard_2019_logo.svg.png" alt="Mastercard" />
          <img src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg" alt="Paypal" />
          <img src="https://upload.wikimedia.org/wikipedia/commons/3/30/American_Express_logo.svg" alt="Amex" />
        </div>
      </div>
    </div>
    <div class="right-section">
<img :src="require('@/assets/man2.png')" alt="Promo Image" class="promo-image" />
    </div>
  </div>
  <div class="company-logos-section">
    <h2>Our members got jobs at these companies</h2>
    <div class="company-logos">
      <div class="company-logo" v-for="company in companies" :key="company.name">
        <img :src="company.logo" :alt="company.name" />
      </div>
    </div>
  </div>
  <div class="testimonial-section">
    <h2>Our members love it</h2>
    <div class="carousel-container">
      <button @click="prevSlide" class="arrow left-arrow">&#10094;</button>
      
      <div class="carousel">
        <div 
          class="testimonial" 
          v-for="(testimonial, index) in visibleTestimonials" 
          :key="index"
        >
          <img :src="testimonial.avatar" alt="User Avatar" class="avatar" />
          <div class="rating">
            <span v-for="star in 5" :key="star" class="star">&#9733;</span>
          </div>
          <p class="username">{{ testimonial.username }} wrote a review for RemoteJobSphere</p>
          <p class="review">{{ testimonial.review }}</p>
        </div>
      </div>
      
      <button @click="nextSlide" class="arrow right-arrow">&#10095;</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      selectedPlan: "",
      testimonials: [
      {
        username: "Raj",
        avatar: "https://randomuser.me/api/portraits/men/1.jpg",
        review: "I found a high-paying remote job in 3 weeks! It's like your own job-hunting genie. Thx!!",
      },
      {
        username: "Viktoria",
        avatar: "https://randomuser.me/api/portraits/women/1.jpg",
        review: "This is the best tool I’ve used for job searching.",
      },
      {
        username: "Aarav",
        avatar: "https://randomuser.me/api/portraits/men/2.jpg",
        review: "Great website to hunt for hidden remote jobs. And they filter out all job scams and ad.",
      },
      {
        username: "Marie",
        avatar: "https://randomuser.me/api/portraits/women/2.jpg",
        review: "I love how easy it was to find my dream remote job using RemoteJobSphere!",
      },
      {
        username: "John",
        avatar: "https://randomuser.me/api/portraits/men/3.jpg",
        review: "A fantastic resource for remote job seekers, highly recommend!",
      },
      {
        username: "Sophia",
        avatar: "https://randomuser.me/api/portraits/women/3.jpg",
        review: "RemoteJobSphere made job hunting so much easier. I got hired within two weeks!",
      },
      {
        username: "Liam",
        avatar: "https://randomuser.me/api/portraits/men/4.jpg",
        review: "An awesome platform for finding legit remote jobs. It saved me a lot of time and effort.",
      },
      {
        username: "Emma",
        avatar: "https://randomuser.me/api/portraits/women/4.jpg",
        review: "I've recommended RemoteJobSphere to all my friends. It’s a game-changer for remote work opportunities!",
      },
    ],
      currentIndex: 0,
      companies: [
        { name: "Amazon", logo: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg" },
        { name: "GitHub", logo: "https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg" },
        { name: "Uber", logo: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Uber_logo_2018.png" },
        { name: "Google", logo: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg" },
        { name: "Slack", logo: "https://upload.wikimedia.org/wikipedia/commons/7/76/Slack_Icon.png" },
        { name: "Spotify", logo: "https://upload.wikimedia.org/wikipedia/commons/1/19/Spotify_logo_without_text.svg" },
        { name: "Zoom", logo: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Zoom_Communications_Logo.svg" },
        { name: "Tesla", logo: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Tesla_Motors.svg" },
      ],
      plans: [
        {
          id: 1,
          name: "1-Week Plan",
          originalPrice: "€2.00",
          discountedPrice: "€1.00",
          pricePerDay: "per day",
          popular: false,
          // price_id: "price_1Q6dNyGYUFZ9bd1e0yupQEEm",
          price_id: "price_1Q6dBkGYUFZ9bd1eDvWy4NGJ"

        },
        {
          id: 2,
          name: "1-Month Plan",
          originalPrice: "€1.90",
          discountedPrice: "€0.90",
          pricePerDay: "per day",
          popular: true, // This one will be highlighted as most popular
          // price_id: "price_1Q6dNyGYUFZ9bd1e0yupQEEm",
          price_id: "price_1QJd08GYUFZ9bd1eRSsZKsFy"         
        },
        {
          id: 3,
          name: "3-Month Plan",
          originalPrice: "€1.70",
          discountedPrice: "€0.85",
          pricePerDay: "per day",
          popular: false,
          // price_id: "price_1Q6dNyGYUFZ9bd1e0yupQEEm",
          price_id: "price_1QJd2yGYUFZ9bd1e9rgmDXVj"
        },
      ],
    };
  },
   computed: {
    visibleTestimonials() {
      return [
        this.testimonials[this.currentIndex],
        this.testimonials[(this.currentIndex + 1) % this.testimonials.length],
        this.testimonials[(this.currentIndex + 2) % this.testimonials.length],
      ];
    },
  },
  methods: {
     nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.testimonials.length;
    },
    prevSlide() {
      this.currentIndex =
        (this.currentIndex - 1 + this.testimonials.length) %
        this.testimonials.length;
    },
    async redirectToStripe(priceId) {
      try {
        const response = await axios.post(
          "https://www.remotejobsphere.com/checkout/create_session",
          {
            price_id: priceId,
          },{
              headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          } 
        );
        window.location.href = response.data.url;
      } catch (error) {
        console.error("Error creating Stripe checkout session:", error);
      }
    },
  },
};
</script>

<style scoped>
.testimonial-section {
  text-align: center;
  padding: 40px;
  
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333;
}

.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel {
  display: flex;
  overflow: hidden;
  width: 70%;
}

.testimonial {
  flex: 1;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.rating {
  color: #00d283;
  margin-bottom: 10px;
}

.star {
  font-size: 1.2rem;
}

.username {
  font-weight: bold;
  margin-bottom: 5px;
}

.review {
  font-size: 1rem;
  color: #666;
  font-style: italic;
}

.arrow {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  padding: 10px;
  color: #333;
}

.left-arrow {
  margin-right: 20px;
}

.right-arrow {
  margin-left: 20px;
}

.arrow:hover {
  color: #00d283;
}

.plan-selection-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px;
  background-color: #6c00f0;
  color: white;
  font-family: Arial, sans-serif;
}

.left-section {
  flex: 1;
}

.right-section {
  /* flex: 1; */
}

.promo-image {
  max-width: 65%;
  height: auto;
}

/* Header Section */
.header-section {
  text-align: left;
  margin-bottom: 20px;
}

.header-section2 h2 {
    text-align: left;

  color: white; /* This makes the header text white */
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

h2 {
  font-size: 2.5rem;
  font-weight: bold;
}

.discount-info {
  background-color: #00d283;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 1.25rem;
  font-weight: bold;
  display: inline-block;
  margin-top: 10px;
}

/* Plan Options */
.plan-options {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.plan {
  border: 2px solid white;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 70%;
  cursor: pointer; /* Makes the plan act like a button */
  position: relative;
}

.most-popular-banner {
  background-color: #ffffff;
  color: #6c00f0;
  font-weight: bold;
  /* padding: 5px 10px; */
  border-radius: 8px 8px 0 0;
  position: absolute;
  top: -1px; /* Adjust this to position the banner correctly */
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 1;
}

.plan:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 15px rgba(255, 255, 255, 0.1);
}

.plan-header {
    display: flex; /* Use flexbox to align items horizontally */
    justify-content: space-between; /* Distribute the space between the plan name and the price container */
    align-items: center; /* Align the items vertically in the center */
    width: 100%; /* Make sure the content spans the whole container */
}

.plan-header h3 {
  font-size: 2.9rem;
  font-weight: bold;
  color: white;
  margin: 0;
}

.price-container {
  text-align: left;
  display: flex;
  flex-direction: column; /* Align elements vertically */
  /* gap: 1px;  */
}

.price-before {
  text-decoration: line-through;
  font-size: 0.8rem;
  color: #f1f1f1;
}

.price-now {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  margin-top: -12px;
  margin-bottom: -12px;

}

.price-day {
  font-size: 0.8rem;
  color: #f1f1f1;
}

/* Footer Section */
.footer-section {
  text-align: left;
  margin-top: 20px;
}

.payment-info {
  font-size: 0.9rem;
  color: white;
}

.payment-icons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.payment-icons img {
  height: 30px;
}

/* Company Logos Section */
.company-logos-section {
  padding: 50px 20px;
  background-color: #f7f9fc;
  text-align: center;
}

.company-logos-section h2 {
  font-size: 2rem;
  margin-bottom: 30px;
  color: #333;
}

.company-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.company-logo {
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 10px;
  width: 150px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.company-logo img {
  max-width: 100%;
  max-height: 60px;
}

.company-logo:hover {
  transform: scale(1.05);
}

/* Adjust text size for smaller screens */
@media (max-width: 768px) {
  .plan-header h3 {
    font-size: 2.4rem; /* Make the plan name smaller */
  }

  .price-container p {
    font-size: 1rem; /* Adjust price text size */
  }

.payment-info {
    font-size: 1rem; /* Adjust font size */
    width: 90%; /* Make the payment info section narrower */
    text-align: left; /* Optionally center align the text */
  }

  .right-section {
    display: none; /* Hide the right section */
  }

  /* Payment Icons Section */
  .payment-icons {
    display: flex;
    justify-content: left; /* Center the icons */
    align-items: center; /* Center the icons vertically */
    gap: 10px; /* Adjust the gap between icons */
    width: 100%; /* Make the payment icons section narrower */
    margin: 0 auto; /* Center align the icons */
    margin-top: 10px;
    flex-wrap: wrap; /* Allow the icons to wrap onto the next line if necessary */
  }

  .payment-icons img {
    height: 30px; /* Maintain icon size */
  }
  .plan {
    width: 100%;  /* Increase the width of the plan on mobile screens */
    max-width: 80%;
  }
}

</style>
