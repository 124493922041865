<template>
  <div class="post-job-page">
    <h1>Post a New Job</h1>

    <!-- Step 1: Basic Job Details -->
    <form v-if="currentStep === 1" @submit.prevent="goToNextStep">
      <h2>Basic Job Details</h2>

      <div class="form-group">
        <label for="title">Job Title</label>
        <input type="text" id="title" v-model="newJob.title" required />
      </div>

      <div class="form-group">
        <label for="description">Job Description</label>
        <textarea id="description" v-model="newJob.description" required></textarea>
      </div>

      <div class="form-group">
        <label for="company">Company Name</label>
        <input type="text" id="company" v-model="newJob.company" required />
      </div>

      <div class="form-group">
        <label for="location">Location</label>
        <input type="text" id="location" v-model="newJob.location" required />
      </div>

       <div class="form-group">
        <label for="company_website">Company Website</label>
        <input type="url" id="company_website" v-model="newJob.company_website" required />
      </div>

      <div class="form-group">
        <label for="apply_link">Apply Link</label>
        <input type="url" id="apply_link" v-model="newJob.apply_link" required />
      </div>

      <button type="submit" class="orange-button">Next</button>
    </form>

    <!-- Step 2: Job Specifications -->
    <form v-if="currentStep === 2" @submit.prevent="goToNextStep">
      <h2>Job Specifications</h2>

      <!-- Job Type Dropdown -->
      <div class="form-group">
        <label for="job_type">Job Type</label>
        <select id="job_type" v-model="newJob.remote_job_type_id">
          <option v-for="type in jobTypeOptions" :key="type.id" :value="type.id">
            {{ type.name }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="workplace">Workplace</label>
        <input type="text" id="workplace" v-model="newJob.workplace" />
      </div>

      <div class="form-group">
        <label for="salary_max">Salary</label>
        <input type="number" id="salary_max" v-model="newJob.salary_max" />
      </div>

      <div class="form-group">
        <label for="currency">Currency</label>
        <input type="text" id="currency" v-model="newJob.currency" />
      </div>

      <div class="form-group">
        <label for="salary_period">Salary Period</label>
        <input type="text" id="salary_period" v-model="newJob.salary_period" />
      </div>

      <button type="button" class="orange-button" @click="goToPreviousStep">Back</button>
      <button type="submit" class="orange-button">Next</button>
    </form>

    <!-- Step 3: Additional Details -->
    <form v-if="currentStep === 3" @submit.prevent="submitJob">
      <h2>Additional Job Details</h2>

      <div class="form-group">
        <label for="experience_level">Experience Level</label>
        <input type="text" id="experience_level" v-model="newJob.experience_level" />
      </div>

      <!-- Education Dropdown -->
      <div class="form-group">
        <label for="education">Education</label>
        <select id="education" v-model="newJob.education">
          <option v-for="level in educationLevels" :key="level" :value="level">
            {{ level }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="language">Language</label>
        <input type="text" id="language" v-model="newJob.language" />
      </div>

      <div class="form-group">
        <label for="employment_option">Employment Option</label>
        <input type="text" id="employment_option" v-model="newJob.employment_option" />
      </div>

      <div class="form-group">
        <label for="availability">Availability</label>
        <input type="text" id="availability" v-model="newJob.availability" />
      </div>

      <div class="form-group">
        <label for="company_size">Company Size</label>
        <input type="text" id="company_size" v-model="newJob.company_size" />
      </div>

      <div class="form-group">
        <label for="work_schedule">Work Schedule</label>
        <input type="text" id="work_schedule" v-model="newJob.work_schedule" />
      </div>

      <div class="form-group">
        <label for="priority">Priority</label>
        <input type="text" id="priority" v-model="newJob.priority" />
      </div>

      <div class="form-group">
        <label for="team_setup">Team Setup</label>
        <input type="text" id="team_setup" v-model="newJob.team_setup" />
      </div>

      <div class="form-group">
        <label for="contact_email">Contact Email</label>
        <input type="email" id="contact_email" v-model="newJob.contact_email" />
      </div>

      <div class="form-group">
        <label for="categories">Categories</label>
        <input type="text" id="categories" v-model="newJob.categories" />
      </div>

      <div class="form-group">
        <label for="tags">Tags</label>
        <input type="text" id="tags" v-model="newJob.tags" />
      </div>

      <button type="button" class="orange-button" @click="goToPreviousStep">Back</button>
      <button type="submit" class="orange-button">Post Job</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      currentStep: 1,
      jobTypeOptions: [], // Stores the job types from the database
      educationLevels: [
        'High School Diploma',
        'Associate Degree',
        'Bachelor’s Degree',
        'Master’s Degree',
        'Doctorate (PhD)',
        'Professional Certification'
      ], // Education options for dropdown
      newJob: {
        title: '',
        description: '',
        company: '',
        location: '',
        remote_job_type_id: null, // Stores selected job type ID
        workplace: '',
        salary_max: 0,
        currency: 'USD',
        salary_period: 'Per year',
        experience_level: '',
        education: '',
        language: '',
        employment_option: '',
        availability: '',
        company_size: '',
        work_schedule: '',
        priority: '',
        team_setup: '',
        contact_email: '',
        categories: '',
        company_website: '',
        apply_link: '',
        tags: '',
      },
    };
  },
  mounted() {
    this.fetchJobTypes();
  },
  methods: {
    fetchJobTypes() {
      // Fetch job types from the backend
      axios.get('https://www.remotejobsphere.com/remote_job_types')
        .then(response => {
          this.jobTypeOptions = response.data; // Store fetched job types
        })
        .catch(error => {
          console.error("Error fetching job types:", error);
        });
    },
    goToNextStep() {
      if (this.currentStep < 3) this.currentStep += 1;
    },
    goToPreviousStep() {
      if (this.currentStep > 1) this.currentStep -= 1;
    },
    submitJob() {
      axios
        .post('https://www.remotejobsphere.com/jobs', this.newJob)
        .then((response) => {
          console.log('Job posted successfully!', response.data);
          this.$router.push('/'); // Redirect to home or job list page after posting
        })
        .catch((error) => {
          console.error('Error posting job:', error);
        });
    },
  },
};
</script>

<style scoped>
.post-job-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

h1, h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.orange-button {
  background-color:#28a745;
  color: white;
  padding: 12px 30px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
