import { createRouter, createWebHistory } from 'vue-router';
import OnboardingProcess from '@/components/onboarding/OnboardingProcess.vue';
import SuccessPage from '@/components/SuccessPage.vue';
import JobsList from '@/components/JobList.vue';
import JobDetail from '@/components/JobDetailPage.vue';
import PostAJob from '@/components/PostAJob.vue';
import UserProfile from '@/components/UserProfile.vue';

import store from '../store';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: OnboardingProcess,
  },
  {
    path: '/success',
    name: 'Success',
    component: SuccessPage,
  },
  {
    path: '/jobs',
    name: 'JobsList',
    component: JobsList,
    meta: { requiresAuth: true },

  },
  {
    path: '/jobs/:id', // Add this for job detail
    name: 'JobDetail',
    component: JobDetail,
    props: true, // Pass route params as props
  },
  {
    path: '/post-a-job',
    name: 'PostAJob', // This must match what you use in your method
    component: PostAJob, // This should point to your PostAJob.vue component
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: UserProfile,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.isAuthenticated) {
      next('/'); // Redirect to login if not authenticated
    } else {
      next(); // Proceed if authenticated
    }
  } else {
    next(); // Proceed if the route doesn’t require authentication
  }
});

export default router;
