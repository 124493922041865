<template>
  <div class="success-page">
    <h1>Payment Successful!</h1>
    <p>Your payment has been processed successfully. Please set a password to complete your profile:</p>

    <!-- Email Field -->
    <div class="form-group">
      <label for="email">Email</label>
      <input
        type="email"
        v-model="email"
        placeholder="Enter your email"
        required
        class="form-input"
        disabled
      />
    </div>

    <!-- Password form -->
    <form @submit.prevent="setPassword">
      <div class="form-group">
        <label for="password">Password</label>
        <input
          type="password"
          v-model="password"
          placeholder="Enter your password"
          required
          class="form-input"
        />
      </div>
      <div class="form-group">
        <label for="passwordConfirmation">Confirm Password</label>
        <input
          type="password"
          v-model="passwordConfirmation"
          placeholder="Confirm your password"
          required
          class="form-input"
        />
      </div>
      <button type="submit" class="submit-btn">Set Password</button>
    </form>

    <p v-if="error" class="error">{{ error }}</p>
    <p v-if="successMessage" class="success">{{ successMessage }}</p>

    <button @click="goToJobs" v-if="passwordSet" class="submit-btn">Go to Jobs List</button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '', // New email field populated from localStorage
      userId: '', // User ID fetched by email
      password: '',
      passwordConfirmation: '',
      error: null,
      successMessage: '',
      passwordSet: false,
    };
  },
  methods: {
    async getUserIdByEmail() {
  try {
    // Make sure to replace 8080 with the correct backend port (e.g., 3000)
    const response = await axios.get('https://www.remotejobsphere.com/users/find_by_email', {
      params: { email: this.email },
    });
    if (response.data && response.data.id) {
      this.userId = response.data.id;
    } else {
      this.error = 'User not found.';
    }
  } catch (error) {
    console.error('Error fetching user ID:', error);
    this.error = 'Unable to fetch user information.';
  }
},
async setPassword() {
  // Clear both error and successMessage initially
  this.error = null;
  this.successMessage = null;

  if (!this.userId) {
    this.error = 'User ID not found.';
    return;
  }

  try {
    // Wrap the data in a 'user' object
    const response = await axios.put(`https://www.remotejobsphere.com/users/${this.userId}`, {
      user: {
        password: this.password,
        password_confirmation: this.passwordConfirmation,
      }
    });

    console.log(response);
    
    // Set successMessage and clear error
    this.successMessage = 'Password set successfully!';
    this.error = null;
    this.passwordSet = true;
  } catch (error) {
    console.error('ERROR', error);

    // Set error message and clear successMessage
    this.error = 'There was an error setting your password. Please try again.';
    this.successMessage = null;
  }
},
    goToJobs() {
      this.$router.push('/jobs'); // Redirects to the jobs page
    },
  },
  mounted() {
    // Get the email from localStorage if it exists and populate the email field
    const storedEmail = localStorage.getItem('email');
    if (storedEmail) {
      this.email = storedEmail;
      // Fetch user ID by email
      this.getUserIdByEmail();
    }
    
    const sessionId = new URLSearchParams(window.location.search).get('session_id');
    if (sessionId) {
      console.log('Session ID:', sessionId);
    }
  },
};
</script>

<style scoped>
.success-page {
  text-align: center;
  padding: 20px;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

p {
  font-size: 1.5rem;
  margin: 20px 0;
  color: #555;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

label {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.form-input {
  padding: 20px;
  width: 60%;
  font-size: 1.5rem;
  border-radius: 10px;
  border: 2px solid #ccc;
}

.submit-btn {
  padding: 20px 40px;
  font-size: 1.5rem;
  border-radius: 10px;
  border: none;
  background-color: #28a745;
  color: #fff;
  cursor: pointer;
  margin-top: 20px;
}

.submit-btn:hover {
  background-color: #218838;
}

p.error {
  color: red;
}

p.success {
  color: green;
}
</style>
