<template>
  <div class="onboarding-container">
    <component 
      :is="currentStepComponent" 
      @update="updateData"
      :onboarding-data="onboardingData" 
      @next-step="goToNextStep"
      @prev-step="goToPrevStep">
    </component>
    <button v-if="step === 15" class="submit-btn" @click="submitData">Submit</button>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash'; // Import Lodash for the conversion
import OnboardingStep1 from './OnboardingStep1.vue';
import PlanSelection from './PlanSelection.vue';

export default {
  data() {
    return {
      step: 1,
      onboardingData: {
        email: '',
        password: 'Welcome1!',
        categories: [],
        salaryRange: { min: 10, max: 50 }, // camelCase
        experience: '',
        experienceLevel: '', // camelCase
        education: '',
        language: '',
        employmentOptions: [], // camelCase
        // availability: '',
        timeForApplications: '', // camelCase
        country: '',
        jobPreferences: [], // camelCase
        workSchedule: '', // camelCase
        teamSetup: '', // camelCase
        companySize: '', // camelCase
        priority: ''
      }
    };
  },
  computed: {
    currentStepComponent() {
      if (this.step <= 1) {
        return `OnboardingStep${this.step}`; 
      }
      this.submitData();
      return 'PlanSelection';    
    }
  },
  methods: {
    async submitData() {
      try {
        // Convert camelCase to snake_case using Lodash before sending
        const convertedData = _.mapKeys(this.onboardingData, (value, key) => _.snakeCase(key));

        // Replace with your backend URL
        // const response = await axios.post('https://www.remotejobsphere.com/users', { user: convertedData });

        const response = await axios.post('https://www.remotejobsphere.com/users', { user: convertedData },{
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          withCredentials: true, // Only if you need cookies or are dealing with sessions
        });
        console.log(response.data.message);
        // this.$router.push('/thank-you'); 
      } catch (error) {
        console.error('Error submitting data:', error);
      }
    },
    updateData(data) {
      this.onboardingData = { ...this.onboardingData, ...data };
    },
    goToNextStep() {
      if (this.step < 15) {
        this.step += 1;
      }
    },
    goToPrevStep() {
      if (this.step > 1) {
        this.step -= 1;
      }
    },
  },
  components: {
    OnboardingStep1,
    PlanSelection,
  }
};
</script>

<style scoped>
.onboarding-container {
  padding: 20px;
  text-align: center;
}

.submit-btn {
  padding: 10px 30px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>
