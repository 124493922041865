import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Import the router
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faUser, faLaptop, faBook, faBullhorn, faPhone, faChartLine, faCopy, faBriefcase, faComment, faLanguage, faBlog, faChalkboardTeacher, faGraduationCap, faHospital, faBalanceScale, faHandshake, faProjectDiagram, faArrowLeft } from '@fortawesome/free-solid-svg-icons'; // Add necessary icons
import store from './store'; 

library.add(faUser, faLaptop, faBook, faBullhorn, faPhone, faChartLine, faCopy, faBriefcase, faComment, faLanguage, faBlog, faChalkboardTeacher, faGraduationCap, faHospital, faBalanceScale, faHandshake, faProjectDiagram, faArrowLeft);

const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(router);
app.use(store);
app.mount('#app');


// createApp(App).use(router).mount('#app'); // Use the router
// app.component('font-awesome-icon', FontAwesomeIcon);
